.initiative-creature {
  /* Note that applying margin in here seems to result in weird issues with the dragging:
  https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/draggable.md#unsupported-margin-setups
  */
  display: flex;
  align-items: stretch;
  max-width: fit-content;
  border: 1px solid gray;
}

.initiative-creature > div {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
}

.initiative-creature > div:first-child, .initiative-creature > div:last-child {
  background: lightgray;
}

.initiative-creature > div:first-child > div,
.initiative-creature > div:last-child > div {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  padding: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}
